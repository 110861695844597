import React from 'react'
import styled from 'styled-components'
import { SubHeading1 } from 'src/components/Typography'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 22px;

    @media (min-width: 1280px) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 36px;
    }
`

const SubHeading = styled(SubHeading1)`
    margin-bottom: 0;

    @media (min-width: 1280px) {
        width: calc(50% - 16px);
        position: relative;
        padding-right: 32px;

        &::after {
            content: '';
            position: absolute;
            border-right: solid 1px #242424;
            top: 0;
            opacity: 0.3;
            bottom: 0;
            right: 0;
        }
    }
`

const Result = styled.div`
    width: 50%;
    color: #FF9718;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;

    @media (min-width: 1280px) {
        font-size: 42px;
        line-height: 52px;
        padding-left: 32px;
    }
`

interface IProps {
    value?: number
    recommended?: number
}

const ResultText: React.FC<IProps> = ({ value, recommended }) => {
    if (value === undefined) return (<></>)

    const getOrdinalNum = (date: Date) => {
        const n = date.getDate()
        return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    }

    const getDateInFuture = (m: number) => {
        const today = new Date()
        const dateInFuture = new Date(today.setMonth(today.getMonth() + m));

        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]

        const addYear = m >= 12 ? ` ${dateInFuture.getFullYear()}` : '';

        return `${months[dateInFuture.getMonth()]}${addYear}`
    }

    const renderMonthText = (m: number) => {
        const months = m < 2 ? "month" : "months"
        return `${m} ${months}`
    }

    const renderRecommendations = () => {
        if (recommended && value > 0) {
            return ( <span>, but we would recommend changing them in <b>{ renderMonthText(recommended) }</b> time, at which point the performance of your tyres will no longer be optimal.</span> )
        }

        if (value > 0) {
            return ( <span>, but we would recommend changing them <b>now</b> as the performance of your tyres is no longer optimal.</span> )
        }

        return ( <span>, and we would recommend changing them <b>now</b> as the performance of your tyres is no longer optimal.</span> )
    }

    return (
        <Wrapper>
            <SubHeading>
            Based on your current mileage your tyres 
                {   
                    value > 0 ?
                    ( <span> will become illegal in <b>{getDateInFuture(value)}</b></span> ) :
                    ( <span> are illegal, please change them immediately before getting on the road</span> )
                    
                }
                {
                    renderRecommendations()
                }
            </SubHeading>
            <Result>{ value > 0 ? renderMonthText(value) : 'Now' }</Result>
        </Wrapper>
    )
}

export default ResultText
