import React, { useReducer, createContext, Dispatch } from 'react'
import { initialState, reducer } from 'src/reducer'
import {
    IStoreType
} from 'src/types'

type ContextProps = {
    page: number
    result?: number
    recommendedResult?: number
    dispatch: Dispatch<IStoreType>
}


export const CalculatorStore = createContext<Partial<ContextProps>>(initialState)

export const ToolProvider = ({ children }: any) => {
    const { Provider } = CalculatorStore
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <Provider value={{ ...state, dispatch}}>
            { children }
        </Provider>
    )
}