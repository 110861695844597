import React from 'react'
import styled from 'styled-components'
import { DepthType } from 'src/types'

const Block = styled.div`
    display: inline-block;
`

const Labels = styled.div<{
    single: boolean
}>`
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: ${({single}) => single ? 'center' : 'space-between'};

    > b:not(:last-child) {
        margin-right: 26px;
    }
`

const Wrapper = styled.div<{
    color: string
}>`
    background-color:  ${({color}) => color};
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    padding: 12px;
    text-transform: uppercase;
    text-align: center;
    height: 60px
`

interface IProps extends DepthType {}

const Badge: React.FC<IProps> = ({
    labels,
    content,
    color,
}) => {
    return (
        <Block>
            <Labels single={labels.length === 1}>
                {
                    labels.map(label => {
                        return (<b key={label}>{label}</b>)
                    })
                }
            </Labels>
            <Wrapper color={color}>
                { content }
            </Wrapper>
        </Block>
    )
}

export default Badge