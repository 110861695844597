import React from "react";
import styled from "styled-components"
import { ImageType  } from "src/types";

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Content = styled.div`
    padding: 60px 20px;
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1280px) {
        padding: 0 68px;
        align-items: flex-start;
    }
`

const Image = styled.img`
    width: 40%;
    display: none;
    object-fit: cover;
    min-height: 825px;
    object-position: center;

    @media (min-width: 600px) {
        display: block;
    }
`

interface IProps {
    hero: ImageType
    children: React.ReactNode
}

const Container: React.FC<IProps> = ({
    hero,
    children
}) => {
    return (
        <Row>
            <Image src={hero.src} alt={hero.alt}/>
            <Content>
                { children }
            </Content>
        </Row>
    )
}

export default Container
