import React from "react"
import { createRoot } from 'react-dom/client'
import { ToolProvider } from './src/store'
import App from './App'

import './global.css'

interface RootElementProps {}

const Root: React.FC<RootElementProps> = () => {
    return (
        <ToolProvider>
            <App />
        </ToolProvider>
    )
}

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(<Root />)
