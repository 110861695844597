import {
    IActions,
    State
} from 'src/types'

export const ACTIONS: IActions = {
    STORE_RESET: 'STORE_RESET',
    CALCULATE: 'CALCULATE'
}

export const initialState: State = {
    page: 0
}

// Tyres will lose 1mm of grip every 3,703
const MM_LOST_PER_THOUSAND_MILES = 1000/3703
const LEGAL_TREAD_LIMIT = 1.6
const RECOMMENDED_TREAD_LIMIT = 4

export const reducer = (state: State, { type, payload }: any): State => {
    switch (type) {
        case ACTIONS.STORE_RESET:
            return initialState

        case ACTIONS.CALCULATE:
            const { miles, tread } = payload

            const gripLostPerMonth = (MM_LOST_PER_THOUSAND_MILES * (miles/1000))
            const treadLeft = tread - LEGAL_TREAD_LIMIT

            const months = Math.floor(treadLeft / gripLostPerMonth)

            const newState = {
                ...state,
                result: months,
                page: 1,
            }

            if (months && tread > RECOMMENDED_TREAD_LIMIT) {
                const recommendMonths = Math.floor((tread - RECOMMENDED_TREAD_LIMIT) / gripLostPerMonth)
                newState.recommendedResult = recommendMonths
            }

            return newState


        default:
            return state
    }
}
