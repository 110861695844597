import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'src/components/Form'
import { HeadingH1 } from 'src/components/Typography'
import Container from 'src/components/Container'
import { ImageType } from 'src/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'

import blueCar from 'src/assets/blue-car.jpg'
import ResultText from 'src/components/ResultText'
import Callout from 'src/components/Callout'
import Tooltip from 'src/components/Tooltip'
import { CalculatorStore } from 'src/store'
import { ACTIONS } from 'src/reducer'

const StartAgain = styled.a`
    align-self: start;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #242424;
    margin: 12px 29px;

    @media (min-width: 1280px) {
        flex-direction: row;
        align-self: center;
        margin: 12px 36px;
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;

    @media (min-width: 1280px) {
        flex-direction: row;
    }
`

const InfoIcon = styled(FontAwesomeIcon)`
    color: #242424;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin: 16px 16px 0 16px;
`

const Heading = styled(HeadingH1)`
    display: flex;
    align-items: center;
`

interface IProps {}

const heroImage: ImageType = {
    src: blueCar,
    alt: 'A blue on a road surrounded by forest'
}

const Result: React.FC<IProps> = () => {
    const [tooltip, setTooltip] = useState<string>('')
    const tooltipContent = 'Tyres lose 1mm of grip every 3,703 miles - therefore your car will lose 0.27mm every 1,000 miles.'

    const store = useContext(CalculatorStore)
    const { dispatch } = store

    if (dispatch === undefined) return (<></>)

    const handleStartAgain = () => {
        dispatch({
            type: ACTIONS.STORE_RESET
        })
    }

    return (
        <Container hero={heroImage}>
            <Heading>
                Your results
                <Tooltip
                    header='How we calculate your results'
                    content={tooltip}
                >
                    <InfoIcon
                        icon={faCircleQuestion}
                        tabIndex={0}
                        onClick={() => setTooltip(tooltipContent)}
                        onMouseEnter={() => setTooltip(tooltipContent)}
                        onMouseLeave={() => setTooltip('')}
                        onTouchEnd={() => setTooltip('')}
                    />
                </Tooltip>
            </Heading>
            <ResultText
                value={ store.result }
                recommended={ store.recommendedResult } 
            />

            <ButtonRow>
                <a href="https://www.halfords.com/tyres/">
                    <Button>
                        Shop tyres
                    </Button>
                </a>
                <StartAgain href='#' onClick={handleStartAgain}>Start again</StartAgain>
            </ButtonRow>
            <Callout />
        </Container>
    )
}

export default Result
