import styled from 'styled-components'

export const HeadingH1 = styled.h1`
    font-weight: 700;
    font-size: 30px;
    line-height: 36.5px;
    margin-bottom: 8px;
    
    @media (min-width: 1280px) {
        font-size: 64px;
        line-height: 78px;
        margin-bottom: 12px;
    }
`

export const SubHeading1 = styled.p`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;

    @media (min-width: 1280px) {
        font-size: 19px;
        line-height: 26px;
    }
`

export const Heading2 = styled.h2`
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 20px;
`
