import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    top: -6px;
    left: -4px;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`

const Content = styled.div`
    position: absolute;
    z-index: 100;
    background: #242424;
    color: #FFF;
    padding: 16px;
    border-radius: 5px;
    right: 0;
    top: calc(18px + 18px + 16px);
    min-width: 200px;

    b {
        display: block;
        font-size: 18px;
        margin-bottom: 8px;
    }

    &::before {
        content: '';
        position:absolute;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid  #242424;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        right: 16px;
        top: -16px;
        z-index: 99;
    }
`

interface IProps {
    header: string
    content: string
    children: React.ReactNode
}

const Tooltip: React.FC<IProps> = ({
    header,
    content,
    children
}) => {
    return (
        <Wrapper>
            {
                content &&
                <Content>
                    <b>{header}</b>
                    {content}
                </Content>
            }
            {children}
        </Wrapper>
    )
}

export default Tooltip
