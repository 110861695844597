import React, { Dispatch } from 'react'
import { 
    InputGroup as BInputGroup,
    Form,
    Button as BButton
} from 'react-bootstrap'
import styled from 'styled-components'

const HInputGroup = styled(BInputGroup)`
    margin: 6px 0;
`

const HInput = styled(Form.Control)`
    padding: 16px 12px;
    font-size: 18px;
    line-height: 21.6px;
    width: 100%;

    &::placeholder {
        color: #D0D5DD;
    }
`

const InputWrapper = styled.div`
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    @media (min-width: 1280px) {
        max-width: 400px;
    }
`

const Label = styled.label`
    font-size: 16px;
    line-height: 20px;
    width: 100%;
`

export const Button = styled(BButton)`
    background-color: #FF9718;
    color: #242424;
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    padding: 12px 30px;
    margin: 16px 0;
    border: none;

    &:active,
    &:hover {
        background-color: #FF9718;
        color: #242424;
        opacity: 0.8;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }

    &:disabled {
        background-color: #D0D5DD;
        color: rgba(0, 0, 0, 0.25);
    }

    @media (min-width: 1280px) {
        margin: 16px 8px 16px 0;
    }
`

interface InputProps {
    id: string
    label: string
    suffix?: string
    placeholder?: string
    onChange: (value: string) => any
    children?: React.ReactNode
    value: string | undefined
    step?: number
}

export const Input: React.FC<InputProps> = ({
    id,
    label,
    suffix,
    placeholder = '',
    onChange,
    children,
    value,
    step = 1
}) => {
    const onInputChanged = (event: any) => {
        return onChange(event.target.value)
    }

    return (
        <InputWrapper>
            <Label htmlFor={id}>
                { label }
                <HInputGroup>
                    <HInput
                        id={id}
                        placeholder={placeholder}
                        arial-label={`${id}_label`}
                        onChange={onInputChanged}
                        onInput={onInputChanged}
                        value={value}
                        type='number'
                        step={ step }
                    />
                    { suffix && (<HInputGroup.Text id={`${id}_suffix`}>{suffix}</HInputGroup.Text>) }
                </HInputGroup>
            </Label>
            { children }
        </InputWrapper>
    )
}