import React from 'react'
import styled from 'styled-components'

import { Heading2 } from './Typography'
import { DepthArray } from 'src/types'
import Badge from './Badge'

const Wrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    padding: 22px 16px;
    margin-top: 44px;
    width: 100%;
    
    @media (min-width: 1280px) {
        padding: 22px 32px;
        margin-top: 52px;
    }
    
    ul {
        padding-left: 1.5rem;
    }

    li {
        margin-bottom: 16px;
    }

    a {
        font-size: inherit;
    }
`

const BadgeGrid = styled.div`
    display: flex;
    grid-gap: 22px;
    margin-bottom: 22px;

    flex-wrap: wrap;

    >div {
        flex: 1;
    }
`

const tyreDepths: DepthArray = [
    {
        content: 'Good',
        labels: ['8mm', '7mm', '6mm'],
        color: '#91BD55'
    },
    {
        content: 'OK',
        labels: ['5mm', '4mm'],
        color: '#FFF151',
    },
    {
        content: 'Inspect Monthly',
        labels: ['3mm'],
        color: '#FF9718',
    },
    {
        content: "Won't last",
        labels: ['2mm'],
        color: '#C73834',
    }
]

interface IProps {}

export const Callout: React.FC<IProps> = () => {
    return (
        <Wrapper>
            <Heading2>Important links and information</Heading2>
            <ul>
                <li>
                    The legal tyre tread depth for cars in the UK and Europe 
                    is <b>1.6mm</b> across the central three-quarters of the tyre.
                </li>
                <li>
                    <b>Accelerating</b> and <b>braking</b> harshly will damage your 
                    tyres, and may result in rapid air loss.
                </li>
                <li>
                    Having over or under inflated tyres can cause <a href="https://www.halfords.com/tyres/advice/abnormal-tyre-wear.html" target="_blank">
                        uneven tyre tread
                    </a>
                </li>
            </ul>

            <BadgeGrid>
                { 
                    tyreDepths.map(item => {
                        return (
                            <Badge
                                color={item.color}
                                labels={item.labels}
                                content={item.content}
                                key={item.content}
                            />
                        )
                    }) 
                }
            </BadgeGrid>

            <a href="https://www.halfords.com/tyres/advice/tread-depth-and-safety.html" target="_blank">
                Want to find out more about tyre tread safety?
            </a>


        </Wrapper>
    )
}

export default Callout