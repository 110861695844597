import React, { useContext, useEffect, useRef } from 'react'
import Calculator from 'src/pages/Calculator'
import Result from 'src/pages/Result';

import 'bootstrap/dist/css/bootstrap.min.css';
import { CalculatorStore } from 'src/store';

interface IProps {}

const App: React.FC<IProps> = () => {
    const store = useContext(CalculatorStore)
    const { dispatch } = store

    if (dispatch === undefined) return (<></>)

    const ref = useRef(null)

    useEffect(() => {
        const height =  ref.current ? ref.current.clientHeight : 750
        window.parent.postMessage(
            {
                height,
                source: 'halfords-tyre-tool.codeandwander.com' 
            },
            '*'
        )
    });

    return (
        <div ref={ref}>
            { 
                store.page === 0 ?
                (<Calculator />) :
                (<Result />)
            }
        </div>
    )
}

export default App
