import React, { useContext, useState } from 'react'
import { CalculatorStore } from 'src/store';
import { Input, Button } from 'src/components/Form'
import { HeadingH1, SubHeading1 } from 'src/components/Typography'
import Container from 'src/components/Container'
import { ImageType } from 'src/types'

import tyreCheckImg from 'src/assets/Haluk-tyre-warranty.jpg'
import { ACTIONS } from 'src/reducer';

interface IProps {}

const heroImage: ImageType = {
    src: tyreCheckImg,
    alt: 'A man testing the tyre depth of a car'
}

const Calculator: React.FC<IProps> = () => {
    const [miles, setMiles] = useState<string>('')
    const [tread, setTread] = useState<string>('')

    const store = useContext(CalculatorStore)
    const { dispatch } = store

    if (dispatch === undefined) return (<></>)

    const handleSeeResults = () => {
        if (miles && tread) {
            dispatch({
                type: ACTIONS.CALCULATE,
                payload: {
                    miles,
                    tread
                } 
            })
        }
    }

    const handleSetTread = (value: string) => {
        const tread = parseFloat(value)

        if (isNaN(tread)) {
            setTread(value)
        }

        if (tread > 0 && tread <= 8) {
            setTread(value)
        }
    }

    return (
        <Container hero={heroImage}>
            <HeadingH1>
                Tyre Life Calculator
            </HeadingH1>
            <SubHeading1>
                Tyre Life Calculator helps you determine how many 
                months you have remaining on your tyres before a 
                replacement is needed
            </SubHeading1>

            <Input
                id='miles'
                label='Miles driven per month'
                placeholder='1,000'
                onChange={setMiles}
                value={miles}
                step={250}
            />

            <Input
                id='tread'
                label='Tyre tread depth'
                placeholder='5'
                suffix='mm'
                onChange={handleSetTread}
                value={tread}
            >
                <a href='https://www.halfords.com/tyres/advice/tread-depth-and-safety.html' target='_blank'>I don't know how to measure the depth</a>
            </Input>

            <Button
                onClick={handleSeeResults}
                disabled={!(tread && miles)}
            >
                See results
            </Button>
        </Container>
    )
}

export default Calculator
